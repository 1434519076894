import React from "react";
import style from "./EndMembership.module.scss";
import { ApiError, EndMembership, EndReason, MembershipService, MetadataService, ConfigurationService } from "../../../api";
import { Configuration } from "../../../api/models/Configuration";
import { Form, Formik } from "formik";
import { EndMembershipFormLabels, EndMembershipFormEndedEducationLabels, EndMembershipFormPensionLabels } from "./EndMembershipFormLabels";
import { EndMembershipFormSchema, EndMembershipSchemaType } from "./EndMembershipFormSchema";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { ApiErrorBody } from "../../../models/apiError";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";


export type IEndMembershipFormState = {
    loading: boolean;
}

export const EndMembershipForm = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const [terminationReasons, setTerminationReasons] = React.useState<NonNullable<EndReason[]>>([]);
    const [configuration, setConfiguration] = React.useState<Configuration>();
    const [loading, setLoading] = React.useState(true);
    const [hasExistingTermination, setHasExistingTermination] = React.useState(false);

    const endMembership= async (formData: Partial<EndMembershipSchemaType>) => {
        let endMembershipData: Partial<EndMembership> = {
            ...formData,
            date: formData.date ? formData.date.toISOString() : undefined,
        };
        try {
            await MembershipService.endMembership(endMembershipData as EndMembership);
            location.reload();
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 400) {
                    let errorInfo : ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
  };

  
  function determineLabels(reason: EndReason | undefined): Record<keyof EndMembership, string> {
    if (reason?.id === configuration?.dm_ophrstudiestop) {
      return EndMembershipFormEndedEducationLabels;
    }
    if (reason?.id === configuration?.dm_ophrpensionist) {
      return EndMembershipFormPensionLabels;
    }
    return EndMembershipFormLabels;
  }

    const GetPreviousTermination = async () => {
        await MembershipService.getEndMembershipInfo().then(previousTerm => {
            if (previousTerm && previousTerm.createdOn && (previousTerm.leaveDate && new Date(previousTerm.leaveDate) > new Date())) {
                setHasExistingTermination(true);
            }
        });
    };

  const GetOptions = async () => {
    setTerminationReasons(await MetadataService.getAllTerminationReason());
    setLoading(false);
  };

  const GetConfiguration = async () => {
    setConfiguration(await ConfigurationService.getConfiguration());
  };

    React.useEffect(() => {
      GetPreviousTermination();
      GetOptions();
      GetConfiguration();
    }, []);

  let form = <Formik
        initialValues={{
            reason: undefined,
            date: new Date(),
        } as Partial<EndMembershipSchemaType> }
        validationSchema={EndMembershipFormSchema}
        onSubmit={async (values :Partial<EndMembershipSchemaType>, { setSubmitting }) => {
            await endMembership(values);
            setSubmitting(false);
        }}
    >
        {({ handleSubmit,
            isSubmitting,
            values,
        }) => {
            const formElements = new GenericFormElements(determineLabels(values.reason));

            let reasonDropDown = <formElements.RecordDropDownFullValue<EndReason>
              disabled={hasExistingTermination} formValueName="reason" options={terminationReasons} loading={loading}/>;
            let optionalDate = <formElements.BasicDateInput formValueName="date" />;

            return (
              <Form onSubmit={handleSubmit}>
                    {reasonDropDown}
                    {values.reason?.includeDate ? optionalDate : ""}
                    <formElements.SubmitButton className={style.submitBtn} loading={isSubmitting} type="submit"
                        disabled={isSubmitting || hasExistingTermination || loading}>{hasExistingTermination && "Under behandling"}</formElements.SubmitButton>
                    <MessageDiv type={MessageType.error} msg={errorMsg} />
                </Form>
            );
          }
        }
  </Formik>;
    return <div>
        <h1>Udmeldelse</h1>
        {form}
    </div>;
};