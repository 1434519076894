/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Configuration } from '../models/Configuration';
import { request as __request } from '../core/request';

export class ConfigurationService {

    /**
     * @returns Configuration Success
     * @throws ApiError
     */
    public static async getConfiguration(): Promise<Configuration> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/configuration`,
        });
        return result.body;
    }

}