import { EndMembership } from "../../../api";
export const EndMembershipFormLabels : Record<keyof EndMembership, string> = {
    "reason" : "Udmeldelse begrundelse",
    "date": "Aktuel Status Ophører",
};

export const EndMembershipFormEndedEducationLabels: Record<keyof EndMembership, string> = {
  "reason": "Udmeldelse begrundelse",
  "date": "Dato for studiestop",
};

export const EndMembershipFormPensionLabels: Record<keyof EndMembership, string> = {
  "reason": "Udmeldelse begrundelse",
  "date": "Startdato for pension/efterløn",
};