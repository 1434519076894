/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event } from '../models/Event';
import type { EventPaymentRequest } from '../models/EventPaymentRequest';
import type { EventRegistrationRead } from '../models/EventRegistrationRead';
import type { EventSignUp } from '../models/EventSignUp';
import { request as __request } from '../core/request';

export class EventService {

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static async getAllEvents(): Promise<Array<Event>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/events`,
        });
        return result.body;
    }

    /**
     * @param courseNumber 
     * @returns Event Success
     * @throws ApiError
     */
    public static async getEvent(
courseNumber: string,
): Promise<Event> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/events/${courseNumber}`,
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @returns EventRegistrationRead Success
     * @throws ApiError
     */
    public static async getRegisteredEvents(): Promise<Array<EventRegistrationRead>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/events/registrations`,
            errors: {
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @returns EventRegistrationRead Success
     * @throws ApiError
     */
    public static async getAttendedEvents(): Promise<Array<EventRegistrationRead>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/events/attended`,
            errors: {
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async register(
requestBody?: EventSignUp,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/events/register`,
            body: requestBody,
            errors: {
                307: `Redirect`,
                400: `Bad Request`,
                406: `Not Acceptable`,
                409: `Conflict`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static async payEvent(
requestBody?: EventPaymentRequest,
): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/events/payment`,
            body: requestBody,
            errors: {
                307: `Redirect`,
                400: `Bad Request`,
                406: `Not Acceptable`,
            },
        });
        return result.body;
    }

    /**
     * @param eventId 
     * @returns string Success
     * @throws ApiError
     */
    public static async cancelRegistrations(
eventId?: string,
): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/events/cancel`,
            query: {
                'eventId': eventId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @param eventMaterialId 
     * @param ticketNumber 
     * @returns string Success
     * @throws ApiError
     */
    public static async getAttachment(
eventMaterialId: string,
ticketNumber?: string,
): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/events/materials/${eventMaterialId}`,
            query: {
                'ticketNumber': ticketNumber,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @param eventId 
     * @returns any Success
     * @throws ApiError
     */
    public static async cancelWaitingListRegistration(
eventId?: string,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/events/cancel/waitingListRegistration`,
            query: {
                'eventId': eventId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

}