/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./event-overview.module.scss";
import type { Event } from "../../../api/models/Event";
import Loader from "../../_GenericElements/Loader/loader";
import LabelValue from "../../_GenericElements/LabelValue/label-value";
import * as api from "../../../api";
import { ApiError, EventOptionalPurchase, EventRegistrationBase, EventRegistrationResponse, PayTransaction } from "../../../api";
import { EventSignupForm } from "../EventSignupForm/event-signupform";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { EventOverviewElement } from "../../../models/umbracoElement";
import { EventOverviewFileTable } from "./event-overview-file-table";
import { DMDialog } from "../../_GenericElements/DMDialog/dm-dialog";
import { DanishDateFns } from "../../../helpers/DanishDateFns";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { LabelValueTable } from "../../_GenericElements/LabelValueTable/LabelValueTable";
import { EventPaymentRequest } from "../../../api/models/EventPaymentRequest";
import { ApiErrorBody } from "../../../models/apiError";
import { Link } from "@mui/material";
import { detailLinkReplaceToken, eventPageLink } from "../../../constants/common-constants";
import { handleEventPaymentError } from "./event-payment-error-handling";
import { EventContactInformation } from "./event-contact-information";

export interface IEventOverviewProps {
    data: string;
    coursenumber: string;
}

export interface IEventOverviewState {
    show: boolean
    loading: boolean
    _event: Event
    notTargetGroup: boolean
    parsedData: EventOverviewElement,
    error: string,
}

const translateYesNo = (answer?: string | null) => {
    if (!answer) {
        return answer;
    }
    if (answer.toLowerCase() === "yes") {
        return "Ja";
    } else if (answer.toLowerCase() === "no") {
        return "Nej";
    } else {
        return answer;
    }
};
const getResponses = (responses?: EventRegistrationResponse[]) => {
    return <LabelValueTable>{responses ? responses.map((e: EventRegistrationResponse) =>
        e ? <LabelValue trClassName={styles.labelvalue} key={`response${e.id}`} label={e.question || "Invalid Question"}
            value={`${translateYesNo(e?.answer) || ""}`} /> :
            null
    ) : null}
    </LabelValueTable>;
};
const getSelectedOptions = (options?: EventOptionalPurchase[]) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <LabelValueTable>{options ? options.map((e: EventOptionalPurchase) =>
        e ? <LabelValue trClassName={styles.labelvalue} key={`option${e.id}`} label={e.name || "-"}
            value={`${(e?.price || "-")} kroner`} /> :
            null
    ) : null}</LabelValueTable>;

};
const getCancelConfirmation = (hasPayment: boolean, requireApproval?: boolean) => {
    let message = "Vi har registreret din afmelding.";
    if (requireApproval) { message = "Vi har registreret din afmelding. Du modtager en mail, når din afmelding er endeligt bekræftet."; } else if (hasPayment) { message = "Tak for din afmelding. Vi tilbagefører kursusgebyret til samme konto indenfor tre dage."; }
    return message;
};

const checkIsMember = (memberstatus: string) => {
    if (memberstatus == "Medlem" || memberstatus == "Under Udmeldelse") {return true;} else {return false;}
};

export const EventOverview = (props: IEventOverviewProps) => {
    const [umbracoSettings] = useState<EventOverviewElement>(JSON.parse(props.data || "{}"));
    const [deregistered, setDeregistered] = useState(false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [notTargetGroup, setNotTargetGroup] = useState(false);
    const [item, setItem] = useState<Event>({ id: "" } as Event);
    const [error, setError] = useState("");
    const [justRegistered, setJustRegistered] = useState(location && location?.search?.includes("registered"));
    const [isMember, setIsMember] = useState(false);

    useEffect(() => {
        (async () => {
            await getData(props.coursenumber);
        })();
    }, []);


    const getData = async (courseNumber: string) => {
        try {
            const _event = await api.EventService.getEvent(courseNumber);
            const user = await api.ContactsService.getContact();
            setIsMember(checkIsMember(user.status||""));

            if (_event.notTargetGroup === true) {

                setNotTargetGroup(true);
            }
            setItem(_event);
        } catch (err) {
            console.error(err);
            setError("Der er sket en fejl. Data kunne ikke hentes.");
        } finally {
            setLoading(false);
        }
    };


    const deRegisterWaitlingList = async (event: Event) => {
        try {
            setSubmitting(true);
            await api.EventService.cancelWaitingListRegistration(event.id);
            setDeregistered(true);

        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 400) {
                    let errorInfo : ApiErrorBody = JSON.parse(e.body);
                    setError(errorInfo.title);
                }
            }
        } finally {
            setSubmitting(false);
        }
    };

    const deregister = async (event: Event) => {
        setSubmitting(true);

        if (event.id === null) {
            return;
        }

        const result = await api.EventService.cancelRegistrations(
            event.id
        );

        setSubmitting(false);

        if (result === "false") {
            return;
        }
        setDeregistered(true);
    };

    const goToPayment = useCallback(async () => {
        if (item.registration?.id) {
            const eventPayment: EventPaymentRequest = {
                eventId: item.id,
                registrationId: item.registration.id,
                courseNumber: item.courseNumber!,
                eventName: item.name || "Arrangement",
            };
            try {
                await api.EventService.payEvent(eventPayment);
            } catch (err) {
                console.error(err);
                if (err instanceof ApiError) {
                    handleEventPaymentError(err);
                }
            }
        }
    }, [item]);

    const address = useMemo(() => { return item.location ? `${item.location?.name}${item.location?.adresse != null ? `: ${item.location?.adresse} - ${item.location?.postalCode ?? ""} ${item.location?.city ?? ""}` : ""}` : ""; }, [item]);
    const start = useMemo(() => { return item.eventStartDate ? DanishDateFns.format(new Date(item.eventStartDate), "dd.MM.yy") : ""; }, [item]);
    const end = useMemo(() => { return item.eventEndDate ? DanishDateFns.format(new Date(item.eventEndDate), "dd.MM.yy") : ""; }, [item]);
    const cancellimit = useMemo(() => { return item.eventCancelLimitDate ? DanishDateFns.format(new Date(item.eventCancelLimitDate), "dd.MM.yy") : ""; }, [item]);
    const date = useMemo(() => { return start && end ? (start === end ? start : `${start} - ${end}`) : ""; }, [item]);
    const time = useMemo(() => { return item.eventStartDate && item.eventEndDate ? `${DanishDateFns.format(new Date(item.eventStartDate), "HH:mm")} - ${DanishDateFns.format(new Date(item.eventEndDate), "HH:mm")}` : ""; }, [item]);
    const price = useMemo(() => { return item.eventPrice != 0 ? `${new Intl.NumberFormat("da-DK").format(item.eventPrice || 0)} kroner` : "Gratis"; }, [item]);
    const detailLink = useMemo(() => { return umbracoSettings.moreDetailLinkTemplate?.replace(detailLinkReplaceToken, item.courseNumber || "") || ""; }, [umbracoSettings, item]);

    const needPayment = useMemo(() => {
        if (item.registration) {
            // has price but not paid, the user need to pay
            const totalpricetoPay = (item.registration?.price || 0) + (item.guestRegistration?.registration?.price || 0);
            return totalpricetoPay > 0 && !item.registration?.hasPaied;
        } else {
            return false;// no registration. no need to pay
        }
    }, [item]);
    const popuptitle = useMemo(() => {
        return item.waitlistItem ?
            umbracoSettings.waitlistRegisteredTitle || "Du er skrevet op på venteliste" :
            umbracoSettings.registeredTitle || "Du er nu tilmeldt";
    }, [item, umbracoSettings]);
    const popuptext = useMemo(() => {
        return item.waitlistItem ?
            umbracoSettings.waitlistRegisteredText || "Vi har registeret din tilmelding på venteliste. Du hører fra os når du er tilmeldt" :
            `${umbracoSettings.registeredText || "Vi har nu registreret din tilmelding."}${item.requireApproval ? " Du modtager en mail, når din deltagelse er endeligt bekræftet." : ""}`;
    }, [item, umbracoSettings]);


    return (
        <div>
            {loading ? <Loader /> :
                item.id && <div>
                    {item.registration || item.waitlistItem ? <div></div> : <EventContactInformation></EventContactInformation>}

                    <div>
                        <h1>{item.name || ""}</h1>
                        {item.waitlistItem && <div className={styles.statusdiv}>
                            <div> {`${item.waitlistItem ? `${umbracoSettings.onWaitlistText || "Du står på venteliste"}` : ""}`}</div>
                        </div>}
                        {!item.isRegisterationOpen && !item.registration && !item.waitlistItem &&
                            <div className={styles.statusdiv}>{umbracoSettings?.deadlineReached || "Tilmeldingsfristen er overskredet"} </div>}
                        <LabelValueTable>
                            <LabelValue label="Beskrivelse" value={item.description ? SantizedRichHtml(item.description) : ""} />
                            {detailLink && <LabelValue label=""
                                value={<a href={detailLink} target="_blank" rel="noreferrer">{umbracoSettings.moreDetailLinkText || "Se den fulde kursusbeskrivelse"}</a>} />}
                            <LabelValue hideIfEmpty={true} label="Dato" value={date || ""} />
                            <LabelValue hideIfEmpty={true} label="Tidspunkt" value={time || ""} />
                            <LabelValue hideIfEmpty={true} label="Placering" value={item.region || ""} />
                            <LabelValue hideIfEmpty={true} label="Adresse" value={address || ""} />
                            {item.onlineUrl && <LabelValue label="Deltag online" value={item.onlineUrl ? <a href={item.onlineUrl} target="_blank" rel="noreferrer">{item.onlineUrl}</a> : ""} />}
                            <LabelValue label="Pris" value={price || ""} />
                            {item.registration && <LabelValue hideIfEmpty={true} label="Afmeldingsfrist" value={cancellimit} />}
                        </LabelValueTable>

                        {item.registration || item.waitlistItem ?
                            <div className={styles.registrationdiv}>

                                <h2>Tilvalg</h2>
                                {item.responses && item.responses.length > 0 &&
                                    getResponses(item.responses || [])
                                }
                                {item.selectedOptionalPurchases && item.selectedOptionalPurchases.length > 0 &&
                                    <>
                                        <h3>Tilkøb</h3>
                                        {getSelectedOptions(item.selectedOptionalPurchases || [])}

                                    </>}
                                {item.guestRegistration && <div className={styles.guestdiv}><h2>Gæst tilmelding</h2>
                                    <LabelValue label="Navn" value={item.guestRegistration.guestName || ""}></LabelValue>
                                    <LabelValue label="Pris" value={(item.guestRegistration?.registration?.price || 0).toString() + " kroner" || "Gratis"}></LabelValue>
                                    {item.guestRegistration.responses &&
                                        getResponses(item.guestRegistration?.responses || [])

                                    }
                                    {item.guestRegistration.selectedOptionalPurchases && item.guestRegistration.selectedOptionalPurchases.length > 0 &&
                                        <>
                                            <h3>Tilkøb (Gæst)</h3>
                                            {getSelectedOptions(item.guestRegistration?.selectedOptionalPurchases || [])}

                                        </>
                                    }</div>}
                                {item.registration && item.registration.status == EventRegistrationBase.status.TILMELDING_GODKENDT &&
                                    <EventOverviewFileTable title={umbracoSettings.materialTitle} materialList={item.eventMaterials} />}

                                {item.registration && item.registration.status == EventRegistrationBase.status.TILMELDING_GODKENDT && item.practicalInfomation && <div className={styles.practicalinfo}>
                                    <h2>Praktisk information</h2>
                                    {SantizedRichHtml(item.practicalInfomation)}
                                </div>}
                                {((item.registration?.price || 0) + (item.guestRegistration?.registration?.price || 0)) > 0 ? <div className={styles.pricediv}><h2>Fuld pris</h2>
                                    <LabelValue label="Total pris" value={((item.registration?.price || 0) + (item.guestRegistration?.registration?.price || 0)).toString() + " kroner"}></LabelValue>
                                </div> : <></>}

                                {/* Unsubscribe normal event registration and waiting list registration */}
                                {!needPayment && <StandardButton style={{marginTop: "50px"}} onClick={() => {
                                    if (!item.waitlistItem) {
                                        deregister(item);
                                    } else {
                                        deRegisterWaitlingList(item);
                                    }
                                }}
                                loading={submitting}
                                showArrow={false}
                                disabled={submitting || !(item.allowCancellation ?? true)} >Afmeld</StandardButton>}

                                {!item.waitlistItem && needPayment && <StandardButton onClick={goToPayment} showArrow={false}
                                >Betal</StandardButton>}
                                {needPayment && <DMDialog title={umbracoSettings.missingPaymentTitle || "Manglende betaling"}
                                    content={umbracoSettings.missingPaymentText || "Vi har ikke registreret din betaling. Betal venligst for at fuldføre tilmelding."} open={true}
                                    onclose={() => { setJustRegistered(false); setItem(item); }}></DMDialog>}
                                {deregistered && <DMDialog title={"Afmelding"}
                                    content={getCancelConfirmation(item.eventPrice != 0, item.cancelRequireApproval && !item.waitlistItem)}
                                    open={true}
                                    onclose={() => { window.location.href = eventPageLink; }}></DMDialog>}
                                {justRegistered &&
                                    <DMDialog title={popuptitle}
                                        content={popuptext} open={true}
                                        onclose={() => { setJustRegistered(false); setItem(item); }}></DMDialog>}
                            </div> :
                            <div>
                                {notTargetGroup ?
                                    <DMDialog
                                        content={umbracoSettings.notTargetGroupText || "Du kan desværre ikke tilmelde dig dette arrangement, da du ikke er i målgruppen. Skal informationerne om dit medlemskab opdateres, kan du gøre det på siden Profil og medlemskab."} open={true}
                                        onclose={() => { }}></DMDialog> :
                                    <EventSignupForm umbracoSettings={umbracoSettings} _event={item} isMember={isMember} />}
                            </div>
                        }
                        <MessageDiv type={MessageType.error} msg={error} />
                    </div>
                </div>
            }
        </div>
    );
};
